import './App.css';
import React from "react";

class App extends React.Component {
    setter = null;
    writer = null;
    cursor = null;

    constructor(props) {
        super(props);
        this.setter = React.createRef();
        this.writer = React.createRef();
        this.cursor = React.createRef();
    }

    setterFocus = () => this.setter.current.focus();

    writeIt = (data, e) => {
        const w = this.writer.current;
        w.innerHTML = this.nl2br(data);

        if (data && e && (13 === e.keyCode || e.which === 13)) {
            this.parseCommand();
        }
    }

    moveIt(count, e) {
        e = e || window.event;
        const keycode = e.keyCode || e.which;
        this.cursor.current.style.left = "0px";
        this.cursor.current.style.top = "-2px";
        if (keycode === 37 && parseInt(this.cursor.current.style.left) >= (0 - ((count - 1) * 10))) {
            this.cursor.current.style.left = parseInt(this.cursor.current.style.left) - 10 + "px";
        } else if (keycode === 39 && (parseInt(this.cursor.current.style.left) + 10) <= 0) {
            this.cursor.current.style.left = parseInt(this.cursor.current.style.left) + 10 + "px";
        }
    }

    parseCommand = () => {
        const value = this.setter.current.value.replace(/[\n\r]/g, '')
        const regexp = '(\\w+)$'
        const matches = value.match(regexp)

        if (matches && matches.hasOwnProperty(0)) {
            this.answerGenerator(matches[0]);
        }
    }

    print = (value) => {
        this.clear();
        const w = this.writer.current;
        w.innerHTML = value
        this.moveIt(value.length);
    }

    clear = () => {
        this.setter.current.value = '';
        this.writer.current.value = '';
    }

    nl2br(txt) {
        return txt.replace(/\n/g, "<br />");
    }

    answerGenerator = (value) => {
        switch (value) {
            case 'help':
                this.print(this.getHelp());
                break;
            case 'about':
                this.print(this.getAbout());
                break;
            case 'skills':
                this.print(this.getSkills());
                break;
            case 'contact':
                this.print(this.getContact());
                break;
            case 'photo':
                this.print(this.getPhoto());
                break;
            default:
                break;
        }
    }

    getHelp = () => {
        return 'SKILLS - list of technical skills<br/>' +
            'ABOUT - Something about me<br/>' +
            'CONTACT - Contact info<br/>' +
            'PHOTO - A photo ASCII<br/>'
    }

    getPhoto = () => {
        return '<img src="/ja.jpeg" width="300"/>'
    }

    getAbout = () => {
        return '  <p>\n' +
            '            Since my kindergarten age, I was in love at first sight in technology. Back then I was messing with Commodore 64,\n' +
            '            then with 486 PC running on 1GB hard drive and couple MB of RAM memory. Known to be totally dedicated and spent countless nights in front\n' +
            '            of computer trying to figure out how something work, when there wasn\'t internet like today is. No tutorials, documentations, manuals online.\n' +
            '        </p>\n' +
            '        <p>\n' +
            '            Learning never ends. That\'s process that was and will be always ongoing.\n' +
            '            From just writing well formatted and commented code, there is constant urge to write clean, readable,\n' +
            '            non-redundant code that can easily adjusted to changing business logic and refactored in no time.\n' +
            '        </p>';
    }

    getSkills = () => {
        return 'BACKEND: PHP, MYSQL/MSSQL, Symfony, Redis, Kafka/RabbitMq <br/> FRONTEND: REACT, ANGULAR-JS';
    }

    getContact = () => {
        return 'Phone: +381 (0)60 032 77 33<br/>' +
            'Email: <a href="mailto:radovicmmilan@gmail.com">radovicmmilan@gmail.com</a> <br/>' +
            'Linkedin: <a target="_blank" href="https://www.linkedin.com/in/milan-radovic/">https://www.linkedin.com/in/milan-radovic/</a> <br/>';
    }

    render() {
        return (
            <div className="frame">
                <h1 className="heading">**** Milan Radovic - Commodore 64 BASIC V2 ****</h1>
                <h2 className="heading">64K ram system 38911 basic bytes free</h2>
                <h2 className="heading">Type help for command list</h2>
                <span className="heading heading_span">Ready .</span>
                <div id="terminal" onClick={this.setterFocus}>
              <textarea
                  ref={this.setter}
                  id="setter"
                  onKeyUp={(e) => this.writeIt(e.target.value, e)}
                  autoFocus
              ></textarea>
                    <div id="getter">
                        <span id="writer" ref={this.writer}></span>
                        <b className="cursor" id="cursor" ref={this.cursor}>H</b>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
